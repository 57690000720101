p{
    margin-right: 0px;
    margin-bottom: 8px;
    margin-left: 0px;
     font-variant-numeric: normal;
      font-variant-east-asian: normal;
       font-stretch: normal;
        font-size: 12px;
         line-height: normal;
          font-family: Arial;
}
.navlink{
    font-family: 'Montserrat';font-size: 40px;
}


.navsection{
  
    list-style:none;
    margin:0;
    padding:0;
    text-align:center;
}
.navsection li{
    display:inline;
}
.navsection a{
    display:inline-block;
    text-decoration: none;
    color: #555157;
    font-size: 1.5rem;
    font-weight:500;
    
    padding:10px 50px;
}

.payments{
    transform: translate(0px, -2%);
}
.payments-container{
    position: relative;
    color: white;
}

.centered {
    position: absolute;
   
    transform: translate(25%, -150%);
  }
  .centered-social {
    position: absolute;
   
    transform: translate(50%, -150%);
  }
  .centered-food {
    position: absolute;
   
    transform: translate(15%, -150%);
  }

  .centered-buisness {
    position: absolute;
   
    transform: translate(30%, -150%);
  }
  .centered-transport {
    position: absolute;
   
    transform: translate(15%, -150%);
  }


  .social{
    transform: translate(0px, -6%);
}
.social-container{
    position: relative;
    color: white;
}

.food{
    transform: translate(0px, -10%);
}
.food-container{
    position: relative;
    color: white;
}
.buisness{
    transform: translate(0px, -14%);
}
.buisness-container{
    position: relative;
    color: white;
}
.transport{
    transform: translate(0px, -18%);
}
.transport-container{
    position: relative;
    color: white;
}

.joinsection{
    transform: translate(0px, -22%);
   
}

.hw-webi-work {
    background: white;
    padding: 0px;
    box-shadow: 0px 0px 15px 10px rgb(211, 211, 211);
    border-radius: 25px;
  }

  .rotate-mobile{
    transform: rotate(45deg) translate(0%, 40%);
  }

  .footer{
   
}

